import { Controller } from '@hotwired/stimulus'
import Cookies from '../../helpers/cookies';

export default class extends Controller {
  static values = {
    currentUrl: String,
    cookieName: { type: String, default: 'statistics' }
  }

  currentCookieArray() {
    return Cookies.get(this.cookieNameValue).split(',') || []
  }

  updateCookie(statisticsArray) {
    Cookies.set(this.cookieNameValue, statisticsArray.join(','), 60 * 24 * 60)
  }

  addStatistic(e) {
    e.preventDefault();

    const newStatistic = e.target.value
    const statisticsArray = this.currentCookieArray()

    statisticsArray.push(newStatistic)

    this.updateCookie(statisticsArray)
    this.refreshFrameWithUpdatedStatistic(newStatistic)
  }

  removeStatistic(e) {
    e.preventDefault();

    const statisticToRemove = e.target.dataset.statistic
    const statisticsArray = this.currentCookieArray().filter(statistic => statistic !== statisticToRemove)

    this.updateCookie(statisticsArray)
    this.refreshFrameWithUpdatedStatistic(null)
  }

  refreshFrameWithUpdatedStatistic(statisticValue) {
    const paramKey = 'statistic'
    const url = new URL(this.currentUrlValue, window.location.origin)

    if (statisticValue) {
      url.searchParams.set(paramKey, statisticValue)
    } else {
      url.searchParams.delete(paramKey)
    }

    document.getElementById('stats').src = url
  }
}