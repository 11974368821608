import { Controller } from '@hotwired/stimulus'
import Toast from 'bootstrap/js/dist/toast'

export default class extends Controller {
  connect() {
    this.toastElement = new Toast(this.element, {})
    this.toastElement.show()
  }

  close() {
    this.toastElement.dispose()
  }
}