import { Controller } from '@hotwired/stimulus'
import Offcanvas from 'bootstrap/js/dist/offcanvas'

export default class extends Controller {
  static targets = [ 'messagesContainer', 'messageContentInput', 'messageSubmitButton' ]

  connect() {
    this.offcanvasElement = new Offcanvas(this.element, {})
    this.offcanvasElement.toggle()
    this.setEventListeners()
    this.toggleSubmitButton()
    this.scrollToBottom()
    this.markConversationRead()
  }

  disconnect() {
    this.markConversationRead()
    let that = this
    this.element.removeEventListener('hidden.bs.offcanvas', function (event) {
      that.dismissConversation()
    })
  }

  setEventListeners() {
    let that = this
    this.element.addEventListener('hidden.bs.offcanvas', function (event) {
      that.dismissConversation()
    })
  }

  hide() {
    this.offcanvasElement.hide()
  }

  dismissConversation() {
    this.offcanvasElement.dispose()
    this.element.closest('turbo-frame').src = undefined
    this.element.remove();
  }

  toggleSubmitButton() {
    this.messageSubmitButtonTarget.disabled = this.messageContentInputTarget.value == ''
  }

  resetMessageContentInput() {
    this.messageContentInputTarget.value = ''
    this.toggleSubmitButton()
    this.scrollToBottom()
  }

  scrollToBottom() {
    this.messagesContainerTarget.scrollTop = this.messagesContainerTarget.scrollHeight - 100;
  }

  markConversationRead() {
    fetch(this.element.dataset.readConversationUrl, {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
  }

  messageConnected() {
    this.scrollToBottom()
  }
 }