// Entry point for the build script in your package.json

import '@hotwired/turbo-rails'
import './controllers/erp'

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

// Load jquery in it's own file for hoisting prevention
import './src/jquery'

import 'chartkick/chart.js'

// START Boostrap
import 'bootstrap/js/dist/alert';
import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
// import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';
// END Bootstrap

require('trix')
require('@rails/actiontext')

require('chartjs-chart-geo')
require('select2')();

document.addEventListener('turbo:load', function (event) {
  // Set variables for offcanvas toggling
  let navbarToggle = document.querySelector('#navbar-offcanvas-toggle')
  let namespaceToggle = document.querySelector('#namespace-offcanvas-toggle')
  let navbarOffcanvas = document.querySelector('#navbar-offcanvas')
  let namespaceOffcanvas = document.querySelector('#namespace-offcanvas')

  // Offcanvas nav
  if (navbarToggle) {
    navbarToggle.addEventListener('click', function () {
      if (namespaceOffcanvas) { namespaceOffcanvas.classList.remove('open') }
      navbarOffcanvas.classList.toggle('open')
    })
  }

  // Namespace nav
  if (namespaceToggle) {
    namespaceToggle.addEventListener('click', function (e) {
      e.preventDefault();
      navbarOffcanvas.classList.remove('open')
      namespaceOffcanvas.classList.toggle('open')
    })
  }
})

document.addEventListener('turbo:before-fetch-response', function (event) {
  if (typeof (event.detail.fetchResponse) !== 'undefined') {
    let redirectLocation = event.detail.fetchResponse.response.headers.get('turbo_location')
    if (redirectLocation) {
      event.preventDefault()
      Turbo.cache.clear()
      Turbo.visit(redirectLocation)
    }
  }
})