import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input', 'output' ]

  connect() {
    this.recalculate();
  }

  recalculate() {
    let total = 0;
    this.inputTargets.forEach(target => {
      total += parseInt(target.value)
    })
    this.outputTarget.innerHTML = total
  }
}
