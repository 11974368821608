import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'container', 'input' ]
  static values = {
    debounce: { type: Number, default: 100 }
  }

  connect() {
    this.initializeEditor()
  }

  initializeEditor() {
    let editor = ace.edit(this.containerTarget)

    editor.setValue(this.inputTarget.value)
    editor.clearSelection();
    editor.session.getUndoManager().reset();
    editor.setOptions({
      fontSize: '14px',
      mode: 'ace/mode/liquid',
      tabSize: 2,
      useSoftTabs: true,
      minLines: Math.max(3, this.inputTarget.rows),
      maxLines: 200,
      showPrintMargin: false,
      wrap: true
    });

    let that = this;

    editor.on('change', function() {
      that.updateInput(editor.getValue())
    });
  }

  updateInput(input) {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.inputTarget.value = input
      this.inputTarget.dispatchEvent(new Event('change'))
    }, this.debounceValue)
  }
}