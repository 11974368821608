import { Controller } from '@hotwired/stimulus'
import PullToRefresh from 'pulltorefreshjs'

export default class extends Controller {
  connect() {
    const standalone = window.matchMedia("(display-mode: standalone)").matches

    if (!standalone) { return }

    PullToRefresh.init({
      distThreshold: 100,
      distMax: 120,
      distReload: 100,
      refreshTimeout: 50,
      iconRefreshing: '<i class="fa-solid fa-spinner fa-spin"></i>',
      shouldPullToRefresh() {
        return !this.mainElement.scrollTop
      }
    });
  }
}