import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['output']
  static values = {
    interval: { type: Number, default: 500 },
    url: String
  }

  connect() {
    if (!this.hasUrlValue) { return }

    setTimeout(this.fetch.bind(this), this.intervalValue);
  }

  fetch() {
    fetch(this.urlValue, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(response => response.text())
      .then(html => {
        if (this.hasOutputTarget) {
          this.outputTarget.innerHTML = html
        } else {
          this.element.outerHTML = html
        }
      })
  }
}
