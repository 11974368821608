import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['permissionsContainer']

  connect() {
    // console.log('PWA Companion Controller connected...')

    this.element.pwaCompanion = this;

    this.registerServiceWorker()
    this.setupPermissionsContainer()
  }

  allowNotifications() {
    this.requestNotificationPermission()
  }

  resetSubscription() {
    this.setupSubscription()
      .then((response) => {
        if (response.ok) {
          alert("Subscription reset")
        } else {
          alert("Subscription reset failed - check logs")
        }
      })
  }

  setupPermissionsContainer() {
    if (typeof Notification === "undefined") { return }

    if (!this.hasPermissionsContainerTarget) { return }

    this.permissionsContainerTarget.classList.add(Notification.permission)
  }

  setBadgeCount(badgeCount) {
    if (!navigator.setAppBadge) { return }

    if (badgeCount == 0) {
      navigator.clearAppBadge();
    } else if (badgeCount > 0) {
      navigator.setAppBadge(badgeCount);
    }
  }

  getCsrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute("content")
  }

  async sendNotificationReadReceipt(notificationId) {
    const response = await fetch(`/notifications/${notificationId}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": this.getCsrfToken()
      }
    })

    return response.json()
  }

  async registerServiceWorker() {
    // browser doesn't support service workers
    if (!navigator.serviceWorker) { return }

    // only register service worker if it's not already registered
    if (!navigator.serviceWorker.controller) {
      try {
        // console.log("Registering service worker")
        await navigator.serviceWorker.register('/service-worker.js')
      } catch (error) {
        // console.log("Service worker registration failed:", error)
      }
    } else {
      // console.log("Service worker already registered")
    }

    let that = this;

    navigator.serviceWorker.addEventListener('message', function (event) {
      const notification = event.data

      switch (notification.action) {
        case 'notification:clicked':
          // console.log('Sending notification read receipt:', notification)
          that.sendNotificationReadReceipt(notification.data.notificationId)
            .then(function (json) {
              that.setBadgeCount(json.badgeCount)
            })

          break
      }
    });
  }

  async getPushSubscription(vapidPublicKey) {
    const registration = await navigator.serviceWorker.getRegistration()
    if (!registration) return null

    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: vapidPublicKey
    })

    return subscription
  }

  async sendSubscriptionToServer(subscription) {
    return fetch("/notifications/subscribe", {
      method: "POST",
      credentials: "same-origin",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-CSRF-Token': this.getCsrfToken()
      },
      body: JSON.stringify({ worker: subscription.toJSON() })
    })
  }

  async setupSubscription() {
    // console.log("Setting up subscription")
    const vapidPublicKey = document.head.querySelector('meta[name="vapid_public_key"]').getAttribute("content")
    // console.log("Get push subscription")
    const subscription = await this.getPushSubscription(vapidPublicKey)
    // console.log("Send subscription to server")
    return await this.sendSubscriptionToServer(subscription)
  }

  async requestNotificationPermission() {
    const permissionResult = await Notification.requestPermission()

    if (permissionResult === "granted") {
      await this.setupSubscription()
      this.permissionsContainerTarget.classList.remove("default")
      this.permissionsContainerTarget.classList.add("granted")
      return true
    }
  }
}
