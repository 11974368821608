import { Controller } from '@hotwired/stimulus'
import Dropzone from "dropzone";

export default class extends Controller {
  connect() {
    this.initializeDropzone();
  }

  initializeDropzone() {
    new Dropzone(`#${this.element.id}`, {
      url: this.element.action,
      paramName: this.element.dataset.paramName,
      parallelUploads: 2
    });
  }
}