import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'initialInput', 'finalInput' ]

  connect() {
    this.finalUpdated();
  }

  finalUpdated() {
    const value = parseInt(this.initialInputTarget.dataset.initialQuantity) - parseInt(this.finalInputTarget.value)
    this.initialInputTarget.value = this.clamp(value, this.initialInputTarget.min, this.initialInputTarget.max)
    this.initialInputTarget.dispatchEvent(new Event('change'));
  }

  initialUpdated() {
    const value = parseInt(this.initialInputTarget.dataset.initialQuantity) - parseInt(this.initialInputTarget.value)
    this.finalInputTarget.value = this.clamp(value, this.finalInputTarget.min, this.finalInputTarget.max)
    this.finalInputTarget.dispatchEvent(new Event('change'));
  }

  reset(e) {
    e.preventDefault();
    this.initialInputTarget.value = this.initialInputTarget.dataset.initialQuantity
    this.finalInputTarget.value = 0
    this.initialInputTarget.dispatchEvent(new Event('change'));
    this.finalInputTarget.dispatchEvent(new Event('change'));
  }

  clamp(value, min, max) {
    if (min != undefined) { value = Math.max(value, min) }
    if (max != undefined) { value = Math.min(value, max) }
    return value
  }
}
