import { Controller } from '@hotwired/stimulus'
import Toast from 'bootstrap/js/dist/toast'

export default class extends Controller {
  connect() {
    if (document.getElementById(this.element.dataset.conversationDomId)) {
      this.element.remove()
    } else {
      this.toastElement = new Toast(this.element, {})
      this.toastElement.show()
    }
  }

  close() {
    this.toastElement.dispose()
  }
}