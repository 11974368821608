import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect() {
    document.addEventListener('autocomplete.change', (event) => {
      if (event.detail['selected'].dataset.universalSearchUrl) {
        window.location.href = event.detail['selected'].dataset.universalSearchUrl
      }
    })
  }

  disconnect() {
    this.collapse();
  }

  expand() {
    this.element.classList.add('active')
  }

  collapse() {
    this.element.classList.remove('active')
    this.inputTarget.value = ''
    this.inputTarget.dispatchEvent(new Event('change'))
  }
}