import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input' ];

  connect() {
    this.inputTarget.style.height = `${this.inputTarget.scrollHeight}px`;
    this.inputTarget.style.overflow = 'hidden';
  }

  resize(event){
    this.inputTarget.style.height = 'auto';
    this.inputTarget.style.height =  `${event.target.scrollHeight}px`;
  }
}