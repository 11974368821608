import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'fulfillmentChannelInput', 'skuInput', 'nameInput' ]
  static values = { suggestNameUrl: String }

  suggestName(e) {
    e.preventDefault();

    let params = {}
    if (this.hasSkuInputTarget) { params.sku_id = this.skuInputTarget.value }
    if (this.hasFulfillmentChannelInputTarget) { params.fulfillment_channel = this.fulfillmentChannelInputTarget.value }

    fetch(this.suggestNameUrlValue + `?${new URLSearchParams(params).toString()}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then(response => response.json())
    .then(json => {
      this.nameInputTarget.value = json.name
    })
  }
}