import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['carrierInput', 'serviceInput']

  carrierChanged(e) {
    e.preventDefault()

    const carrier = this.carrierInputTarget.value

    fetch(`/shipping/carriers/${carrier}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then(response => response.json())
    .then(json => {
      if (json) {
        let options = [];

        json.services.forEach(function(service) {
          options.push("<option value='" + service.name + "'>" + service.name + "</option>");
        })

        this.serviceInputTarget.innerHTML = options.join();
      } else {
        this.resetServiceInput()
      }
    })
  }

  resetServiceInput() {
    this.serviceInputTarget.value = ''
    this.serviceInputTarget.innerHTML = "<option value=''></option>"
    this.serviceInputTarget.dispatchEvent(new Event('change'));
  }
}