import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'button', 'record' ]
  static values = { maxRecords: Number }

  connect() {
    this.toggleButton();
  }

  toggleButton() {
    if (this.recordTargets.length >= this.maxRecordsValue ) {
      this.buttonTarget.classList.add('d-none')
    } else {
      this.buttonTarget.classList.remove('d-none')
    }
  }

  recordTargetConnected() {
    this.toggleButton();
  }

  recordTargetDisconnected() {
    this.toggleButton();
  }
}