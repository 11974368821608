import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() {
    this.toggleTargets();
  }

  change() {
    this.toggleTargets();
  }

  toggleTargets() {
    let select = this.element

    Array.from(select.options).forEach((option) => {
      if (!option.hasAttribute('data-target')) {
        return;
      }

      let targetElement = document.getElementById(option.dataset.target.substring(1));
      if (option.value == select.value) {
        targetElement.classList.remove('d-none');
      } else {
        targetElement.classList.add('d-none');
      }
    })
  }
}
