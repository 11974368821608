import { Application } from "@hotwired/stimulus"
import { Autocomplete } from 'stimulus-autocomplete'
import Sortable from 'stimulus-sortable'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
application.register('autocomplete', Autocomplete)
application.register('sortable', Sortable)

window.Stimulus   = application

export { application }