import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'output' ]

  disconnect() {
    this.stopPrevious()
  }

  update(e) {
    let query = e.srcElement.value

    this.debounce(query)
  }

  stopPrevious() {
    if (this.debouncer) {
      clearInterval(this.debouncer)
    }
  }

  debounce(query) {
    let delay = parseInt(this.data.get('debounce')) || 750
    this.stopPrevious()

    this.debouncer = setInterval(() => {
      this.fetchPreview(query)
      this.stopPrevious()
    }, delay, query)
  }

  fetchPreview(query) {
    fetch(this.element.dataset.url, {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify({ query: query}),
      headers: {
        "Content-Type": "application/json",
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => response.text())
    .then(html => {
      this.outputTarget.innerHTML = html
    })
  }
}