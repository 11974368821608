import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'availableInput', 'adjustmentInput', 'skuInput' ]
  static values = { inventoryUrl: String }

  connect() {
    this.adjustmentUpdated();
  }

  adjustmentUpdated() {
    this.availableInputTarget.value = parseInt(this.availableInputTarget.dataset.initialAvailableQuantity) + parseInt(this.adjustmentInputTarget.value)
    this.availableInputTarget.dispatchEvent(new Event('change'));
  }

  availableUpdated() {
    this.adjustmentInputTarget.value = parseInt(this.availableInputTarget.value) - parseInt(this.availableInputTarget.dataset.initialAvailableQuantity) 
    this.adjustmentInputTarget.dispatchEvent(new Event('change'));
  }

  reset(e) {
    e.preventDefault();
    this.availableInputTarget.value = this.availableInputTarget.dataset.initialAvailableQuantity
    this.adjustmentInputTarget.value = 0
    this.availableInputTarget.dispatchEvent(new Event('change'));
    this.adjustmentInputTarget.dispatchEvent(new Event('change'));
  }

  skuSelected() {
    fetch(`${this.inventoryUrlValue}/skus/${this.skuInputTarget.value}`, {
      method: 'GET',
      credentials: 'same-origin',
    })
    .then(response => response.json())
    .then(json => {
      const available = json['available']

      this.adjustmentInputTarget.value = 0
      this.availableInputTarget.value = available

      this.availableInputTarget.dataset.initialAvailableQuantity = available
      this.adjustmentInputTarget.min = -available

      this.availableInputTarget.disabled = false
      this.adjustmentInputTarget.disabled = false

      this.adjustmentInputTarget.dispatchEvent(new Event('change'));
    })
  }
}
