import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input', 'warehouse', 'quantity' ]

  warehouseSelected(warehouseId) {
    let lineItemQuantity = 0;
    const remainingQuantity = this.element.dataset.remaining;

    this.warehouseTargets.forEach(warehouse => {
      warehouse.classList.remove('warehouse-selected')

      if (warehouse.dataset.warehouseId == warehouseId) {
        const warehouseAvailable =  warehouse.dataset.available;
        lineItemQuantity = Math.min(remainingQuantity, warehouseAvailable)
        warehouse.classList.add('warehouse-selected')
      }
    })

    this.inputTarget.value = lineItemQuantity
  }

  increaseQuantity(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    const oldQuantity = parseInt(this.inputTarget.value)
    const newQuantity = Math.min(oldQuantity + 1, this.inputTarget.max)

    this.processQuantityUpdate(oldQuantity, newQuantity)
  }

  decreaseQuantity(e) {
    e.preventDefault()
    e.stopImmediatePropagation()

    const oldQuantity = parseInt(this.inputTarget.value)
    const newQuantity = Math.max(oldQuantity - 1, this.inputTarget.min)

    this.processQuantityUpdate(oldQuantity, newQuantity)
  }

  processQuantityUpdate(oldQuantity, newQuantity) {
    if ( oldQuantity == newQuantity ) { return }

    this.inputTarget.value = newQuantity
    this.quantityTarget.innerHTML = newQuantity

    const form = this.inputTarget.form;
    
    fetch(form.action, {
      method: form.method,
      body: $(form).serialize(),
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(response => response.text())
    .then(html => {
      this.outboundShipmentLineItemsUpdated()
      this.element.outerHTML = html
    })
  }

  outboundShipmentLineItemsUpdated() {
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('outbound-shipment-items-updated', true, true, null)
    this.element.dispatchEvent(event)
  }
}
