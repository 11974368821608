import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['icon']

  toggle(e) {
    e.preventDefault()

    if (this.element.classList.contains('expanded-card')) {
      this.hide()
    } else {
      this.show()
    }
  }

  show () {
    this.element.classList.add('expanded-card')
    this.iconTarget.classList.remove('fa-maximize')
    this.iconTarget.classList.add('fa-minimize')

    this.backdrop = document.createElement('div')
    this.backdrop.id = 'expanded-card-backdrop'
    this.backdrop.classList.add('modal-backdrop', 'show')

    let that = this

    this.backdrop.addEventListener('click', function (event) {
      that.hide()
    })

    document.body.appendChild(this.backdrop)
  }

  hide() {
    this.element.classList.remove('expanded-card')
    this.iconTarget.classList.remove('fa-minimize')
    this.iconTarget.classList.add('fa-maximize')

    this.backdrop.remove()
  }
}
