import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'shippingBoxSelect', 'dimensionInput', 'lengthInput', 'widthInput', 'heightInput',
    'shippingBoxWeightInput', 'contentQuantityInput', 'contentsWeightInput',
    'shippablePackagingInput', 'overweightAlert'
  ]

  selectedShippingBox() {
    return this.shippingBoxSelectTarget.options[this.shippingBoxSelectTarget.selectedIndex]
  }

  shippingBoxSelected(e) {
    e.preventDefault();

    let shippingBox = this.selectedShippingBox()

    if (shippingBox.value == '') {
      this.resetDimensions();
    } else {
      if (this.hasShippingBoxWeightInputTarget) {
        this.shippingBoxWeightInputTarget.value = shippingBox.getAttribute('data-weight-in-lbs')
      }
      this.setDimension(shippingBox);
    }
  }

  setDimension(target) {
    this.lengthInputTarget.value = target.getAttribute('data-length-in-inches')
    this.widthInputTarget.value = target.getAttribute('data-width-in-inches')
    this.heightInputTarget.value = target.getAttribute('data-height-in-inches')
    this.lengthInputTarget.disabled = true
    this.widthInputTarget.disabled = true
    this.heightInputTarget.disabled = true
  }

  resetDimensions() {
    this.shippingBoxSelectTarget.disabled = false

    if (this.hasShippingBoxWeightInputTarget) {
      this.shippingBoxWeightInputTarget.disabled = false
    }

    this.dimensionInputTargets.forEach(target => {
      target.value = ''
      target.disabled = false
    });
  }

  contentsUpdated(e) {
    e.preventDefault();

    let totalWeight = 0.0
    let totalQuantity = 0

    this.contentQuantityInputTargets.forEach(input => {
      let contentQuantity = parseInt(input.value)
      let contentWeight = parseFloat(input.getAttribute('data-weight-in-lbs'))

      totalQuantity += contentQuantity
      totalWeight += contentQuantity * contentWeight
    });

    if (totalQuantity != 1) {
      this.shippablePackagingInputTarget.disabled = false
      this.shippablePackagingInputTarget.checked = false
      this.shippablePackagingInputTarget.disabled = true
      this.resetDimensions();
    } else {
      this.shippablePackagingInputTarget.disabled = false
    }

    this.contentsWeightInputTarget.value = totalWeight

    if (this.hasOverweightAlertTarget) {
      this.overweightAlertTarget.remove()
    }
  }

  shippablePackagingSelected(e) {
    e.preventDefault();

    if (this.shippablePackagingInputTarget.checked) {
      let skuInput = null;

      this.contentQuantityInputTargets.forEach(input => {
        if (input.value == 1) { skuInput = input }
      });

      this.shippingBoxSelectTarget.value = ''
      this.shippingBoxSelectTarget.disabled = true
      this.shippingBoxWeightInputTarget.value = ''
      this.shippingBoxWeightInputTarget.disabled = true
      this.contentsWeightInputTarget.value = skuInput.getAttribute('data-weight-in-lbs')
      this.setDimension(skuInput);
    } else {
      this.resetDimensions();
    }
  }
}