import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'variant', 'price' ]

  variantSelected(e) {
    var selectedOption = this.variantTarget.options[this.variantTarget.selectedIndex]
    this.priceTarget.value = selectedOption.getAttribute('data-price')
  }
}
