import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    options: { type: Object, default: {} }
  }

  connect() {
    let defaultOptions = {
      theme: 'bootstrap-5',
      width: $(this).data('width') ? $(this).data('width') : $(this).hasClass('w-100') ? '100%' : 'style',
      placeholder: 'Select',
      closeOnSelect: false,
      allowClear: true,
      dropdownParent: $(this.element).parent(),
    }


    $(this.element).select2(
      Object.assign(defaultOptions, this.optionsValue)
    )

    $(this.element).on('select2:select select2:unselect select2:clear', function (e) {
      e.currentTarget.dispatchEvent(new Event('change'))
    })
  }

  disconnect() {
    $(this.element).select2('destroy');
  }
}