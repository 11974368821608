import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'lineItem', 'warehouse', 'shipmentTypeContainer' ]

  connect() {
    if (this.element.dataset.refreshLineItemsOnConnect ) {
      this.outboundShipmentLineItemsUpdated()
    }
  }

  warehouseSelected(e) {
    const warehouseId = e.currentTarget.value

    this.warehouseTargets.forEach(target => {
      if (target.dataset.warehouseId == warehouseId) {
        target.classList.add('warehouse-selected')
      } else {
        target.classList.remove('warehouse-selected')
      }
    })

    this.lineItemTargets.forEach(target => {
      this.application.getControllerForElementAndIdentifier(target, 'outbound-shipment--line-item').warehouseSelected(warehouseId)
    })

    if (e.currentTarget.dataset.shipmentTypeRequired == 'true') {
      this.shipmentTypeContainerTarget.classList.remove('d-none')
    } else {
      this.shipmentTypeContainerTarget.classList.add('d-none')
    }
  }

  outboundShipmentLineItemsUpdated() {
    const event = document.createEvent('CustomEvent')
    event.initCustomEvent('outbound-shipment-items-updated', true, true, null)
    this.element.dispatchEvent(event)
  }
}
