import { Controller } from '@hotwired/stimulus'
import { topojson } from "chartjs-chart-geo";
import statesTopoJson from "us-atlas/states-10m.json";

export default class extends Controller {
  static targets = ['canvas']
  static values = { format: { type: String, default: 'integer' } }

  connect() {
    this.drawChart()
  }

  drawChart() {
    const nation = topojson.feature(statesTopoJson, statesTopoJson.objects.nation).features[0];
    const states = topojson.feature(statesTopoJson, statesTopoJson.objects.states).features;
    let labels = []
    let data = []

    let that = this

    fetch(this.canvasTarget.dataset.url).then((r) => r.json()).then((sales) => {
      Object.keys(sales).forEach((key, index) => {
        var feature = states.find((state) => state.properties.name === key)
        if (feature) {
          labels.push(feature)
          data.push({ feature: feature, value: parseFloat(sales[key]) })
        }
      })

      const chart = new Chart(this.canvasTarget.getContext('2d'), {
        type: 'choropleth',
        data: {
          labels: labels.map((d) => d.properties.name),
          datasets: [{
            label: 'States',
            outline: nation,
            data: data,
          }]
        },
        options: {
          legend: {
            display: false
          },
          scale: {
            projection: 'albersUsa'
          },
          geo: {
            colorScale: {
              display: false,
              position: 'bottom',
              quantize: 20,
              legend: {
                position: 'bottom-right',
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (item, data) {
                let stateName = data.labels[item.index]
                let dataLabel = `${stateName}: `

                if (that.formatValue == 'currency') {
                  dataLabel += `${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.value)}`;
                } else {
                  dataLabel += item.value
                }

                return dataLabel
              }
            }
          }
        }
      });
    });
  }

}
