import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input' ]

  templateUpdated(e) {
    let isDisabled = e.target.value != ''

    this.inputTargets.forEach(input => {
      input.disabled = isDisabled
    })
  }
}