import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['results']

  disconnect() {
    this.stopPrevious()
  }

  search(e) {
    let query = e.srcElement.value

    this.debounce(query)
  }

  stopPrevious() {
    if (this.debouncer) {
      clearInterval(this.debouncer)
    }
  }

  debounce(query) {
    let delay = parseInt(this.data.get('debounce')) || 500
    this.stopPrevious()

    this.debouncer = setInterval(() => {
      this.fetchResults(query)
      this.stopPrevious()
    }, delay, query)
  }

  fetchResults(query) {
    var uri = this.element.dataset.url + '?q=' + encodeURIComponent(query);

    fetch(uri, {
      method: 'GET',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        'Accept': 'text/javascript',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => response.text())
    .then(html => {
      this.resultsTarget.innerHTML = html
    })
  }
}