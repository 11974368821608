import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'select', 'nameInput', 'companyInput',
    'streetOneInput', 'streetTwoInput', 'cityInput', 'stateInput',
    'postalCodeInput', 'countryInput', 'phoneNumberInput', 'addressTypeInput'
  ]

  selected(e) {
    e.preventDefault();

    let address = this.selectedAddress()

    if (address.value == '') {
      this.resetAddress();
    } else {
      this.setAddress(address);
    }
  }

  setAddress(address) {
    this.addressInputTargets().forEach(targetName => {
      if (this[`has${targetName.charAt(0).toUpperCase() + targetName.slice(1)}Target`]) {
        let value = address.dataset[targetName.replace('Input', '')]
        if (value == undefined) { value = '' }

        this[`${targetName}Target`].value = value
      }
    })
  }

  resetAddress() {
    this.addressInputTargets().forEach(targetName => {
      if (this[`has${targetName.charAt(0).toUpperCase() + targetName.slice(1)}Target`]) {
        this[`${targetName}Target`].value = ''
      }
    })
  }

  selectedAddress() {
    return this.selectTarget.options[this.selectTarget.selectedIndex]
  }

  addressInputTargets() {
    return this.constructor.targets.filter(target => target.endsWith('Input'))
  }
}