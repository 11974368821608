import { Controller } from '@hotwired/stimulus'
import 'daterangepicker/daterangepicker.js';
import moment from 'moment';

export default class extends Controller {
  connect() {
    this.initializePicker()
  }

  initializePicker() {
    const emptyDate = this.element.value == '';
    const minDate = this.element.dataset.minDate || '10/01/2017';
    const maxDate = this.element.dataset.maxDate || moment();
    const singleDate = this.element.dataset.singleDate == 'true'
    const timePicker = this.element.dataset.timePicker == 'true'

    let options = {
      startDate: this.element.dataset.startDate,
      endDate: this.element.dataset.endDate,
      minDate: minDate,
      maxDate: maxDate,
      timePicker: timePicker
    }

    if (singleDate) {
      let localeFormat = timePicker ? 'YYYY-MM-DD hh:mm A' : 'YYYY-MM-DD';

      Object.assign(options, {
        singleDatePicker: true,
        showDropdowns: true,
        locale: {
          format: localeFormat,
          cancelLabel: 'Clear'
        }
      })
    } else {
      Object.assign(options, {
        ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'Last 365 Days': [moment().subtract(365, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
          'This Year': [moment().startOf('year'), moment()],
          'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
          'All Time': [minDate, moment()]
        },
        alwaysShowCalendars: true
      })
    }

    let dateRangePicker = $(this.element).daterangepicker(options);

    dateRangePicker.on('apply.daterangepicker', function(ev, picker) {
      const form = picker.element.closest('form')[0]
      const onApply = picker.element[0].dataset.onApply

      switch(onApply) {
        case 'submit':
          form.requestSubmit()
          break;
        case 'nothing':
          break;
        default:
          picker.element[0].dispatchEvent(new Event('change'));
          break;
      }
    });

    if (singleDate) {
      if (emptyDate) {  this.element.value = '' }

      dateRangePicker.on('cancel.daterangepicker', function(ev, picker) {
        $(this).val('');
      });
    }

  }
}
