import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 
    'customerSelect', 'buttonContainer', 'nameInput', 'companyInput',
    'streetOneInput', 'streetTwoInput', 'cityInput', 'stateInput',
    'postalCodeInput', 'countryInput', 'phoneNumberInput'
  ]

  connect() {
    this.toggleButtonContainer();
  }

  toggleButtonContainer() {
    var selectedCustomer = this.selectedCustomer()
    if (selectedCustomer.value == '') {
      this.buttonContainerTarget.style.opacity = '0';
    } else {
      this.buttonContainerTarget.style.opacity = '100';
    }
  }

  customerSelected(e) {
    e.preventDefault();

    this.resetCustomerAddress();
    this.toggleButtonContainer();
  }

  setCustomerDefaultAddress(e) {
    e.preventDefault()
    var selectedCustomer = this.selectedCustomer()

    this.nameInputTarget.value = selectedCustomer.getAttribute('data-name')
    this.companyInputTarget.value = selectedCustomer.getAttribute('data-company')
    this.streetOneInputTarget.value = selectedCustomer.getAttribute('data-street-one')
    this.streetTwoInputTarget.value = selectedCustomer.getAttribute('data-street-two')
    this.cityInputTarget.value = selectedCustomer.getAttribute('data-city')
    this.stateInputTarget.value = selectedCustomer.getAttribute('data-state')
    this.postalCodeInputTarget.value = selectedCustomer.getAttribute('data-postal-code')
    this.countryInputTarget.value = selectedCustomer.getAttribute('data-country')
    this.phoneNumberInputTarget.value = selectedCustomer.getAttribute('data-phone-number')
  }

  resetCustomerAddress(e) {
    if (e) {
      e.preventDefault()
    }

    this.nameInputTarget.value = ''
    this.companyInputTarget.value = ''
    this.streetOneInputTarget.value = ''
    this.streetTwoInputTarget.value = ''
    this.cityInputTarget.value = ''
    this.stateInputTarget.value = ''
    this.postalCodeInputTarget.value = ''
    this.countryInputTarget.value = ''
    this.phoneNumberInputTarget.value = ''
  }

  selectedCustomer() {
    return this.customerSelectTarget.options[this.customerSelectTarget.selectedIndex]
  }
}