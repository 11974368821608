import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'output' ]

  connect() {
    if (this.element.dataset.fetchOnConnect == 'true' ) {
      this.fetchCount()
    }
  }

  fetchCount() {
    fetch(this.element.dataset.url, {
      headers: {
        'Content-Type': 'application/json',
      }
    })
    .then(response => response.json())
    .then(json => {
      if (json.count > 0) {
        this.outputTarget.textContent = json.count
        this.outputTarget.classList.remove('d-none')
      } else {
        this.outputTarget.textContent = ''
        this.outputTarget.classList.add('d-none')
      }
    })
  }

  markRead() {
    this.outputTarget.textContent = ''
    this.outputTarget.classList.add('d-none')
  }
}