import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ 'input' ]

  connect () {
    this.update();
  }

  update() {
    let value = this.inputTarget.value;
    let onNegativeClass = this.element.dataset.onNegative
    let onPositiveClass = this.element.dataset.onPositive

    if (value == 0) {
      this.element.classList.remove(onPositiveClass)
      this.element.classList.remove(onNegativeClass)
    } else if (value < 0) {
      this.element.classList.remove(onPositiveClass)
      this.element.classList.add(onNegativeClass)
    } else {
      this.element.classList.add(onPositiveClass)
      this.element.classList.remove(onNegativeClass)
    }
  }
}
